/* global site_params, imagesLoaded */
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import intersectElements from './intersectElements';

import 'smartbanner.js/dist/smartbanner.min.js';

(function($) {
    //Image Gallery JS
    function getImageArrayIndexByImageUrl(url, title) {
        for (var key in imageArray) {
            if ((imageArray[key].href === url) && (imageArray[key].title === title)) {
                return key
            }
        }
        return 0;
    }

    function replaceSVGs() {
        $('img.svg').each(function () {
            var _this = $(this);
            var imgID = _this.attr('id');
            var imgClass = _this.attr('class');
            var imgURL = _this.attr('src');

            jQuery.get(imgURL, function (data) {
                var _svg = $(data).find('svg');
                if (typeof imgID !== 'undefined') {
                    _svg = _svg.attr('id', imgID);
                }
                if (typeof imgClass !== 'undefined') {
                    _svg = _svg.attr('class', imgClass + ' replaced-svg');
                }
                _svg = _svg.removeAttr('xmlns:a');
                if (!_svg.attr('viewBox') && _svg.attr('height') && _svg.attr('width')) {
                    _svg.attr('viewBox', '0 0 ' + _svg.attr('height') + ' ' + _svg.attr('width'));
                }
                _this.replaceWith(_svg);
            }, 'xml');
        });
    }

    function scrollToPlusHeader(target) {
        var hash = window.location.hash;
        if (hash) {
            window.location.hash = "";
        }

        if ($(window).width() > 767) {
            $("html, body").animate({ scrollTop: $(target).offset().top - 250 }, 500);
        } else {
            $("html, body").animate({ scrollTop: $(target).offset().top - 200 }, 500);
        }
    }

    var cp_obj_array = {};

    /**
     * Function to initialise Crafty Clicks options for relevant fields
     */
    function initialiseCraftyClicks(key, postcode_field, fields) {
        var results_div = postcode_field.next('.frm_html_container').find('.cc-results');

        // console.log(postcode_field);
        if (results_div.length > 0) {
            if (results_div.attr('id') === undefined) {
                results_div.attr('id', 'cc-results-' + key);
            }
        }

        var fields_ids = [];

        fields.each(function (index) {
            var input_id = $(this).find('input, select').attr('id');
            if (input_id.indexOf("line1") >= 0) {
                fields_ids['address_1'] = input_id;
            }
            if (input_id.indexOf("line2") >= 0) {
                fields_ids['address_2'] = input_id;
            }
            if (input_id.indexOf("city") >= 0) {
                fields_ids['city'] = input_id;
            }
        });

        cp_obj_array[key] = CraftyPostcodeCreate();
        cp_obj_array[key].set("access_token", "bb489-58eb1-d598a-05b50");
        cp_obj_array[key].set("result_elem_id", results_div.attr('id'));
        cp_obj_array[key].set("form", "");
        cp_obj_array[key].set("elem_street1", fields_ids['address_1']);
        cp_obj_array[key].set("elem_street2", fields_ids['address_2']);
        cp_obj_array[key].set("elem_town", fields_ids['city']);
        cp_obj_array[key].set("elem_postcode", postcode_field.find('input').attr('id')); //must be the id of the postcode lookup field
    }

    /**
     * Dropdown
     */
    var dropdown = {
        setup: function() {
            $(document).on('show.bs.dropdown', '[data-bs-toggle="dropdown"]', this.onShow.bind(this));
            $(document).on('hide.bs.dropdown', '[data-bs-toggle="dropdown"]', this.onHide.bind(this));
        },
        onShow: function(evt) {
            $(evt.target).closest('.dropdown').addClass('show');
        },
        onHide: function(evt) {
            $(evt.target).closest('.dropdown').removeClass('show');
        },
    };

    /**
     * Add to Cart Variation
     */
    var select2 = {
        setup: function() {
            if (! $().selectWoo) return;

            $('select.js-select2:visible').each(function() {
                var $this = $(this);

                var args = {
                    placeholder: $this.attr('data-placeholder') || $this.attr('placeholder') || '',
                    label: $this.attr('data-label') || null,
                    width: '100%',
                    dropdownParent: $this.parent()
                };

                $(this)
                    .on('select2:select', function() {
                        $(this).trigger('focus');
                    })
                    .selectWoo(args);
            });
        },
    };

    /**
     * Header Search
     */
    var headerSearch = {
        $el: $('.js-header-search'),
        $toggleEl: $('.js-header-search-toggle'),
        isOpen: false,
        setup: function() {
            this.$el.on('show.bs.collapse', this.onOpen.bind(this));
            this.$el.on('hide.bs.collapse', this.onClose.bind(this));
            $(document).on('keydown', this.onKeydown.bind(this));
            $(document).on('click touchstart', this.onClickOutside.bind(this));
        },
        onOpen: function() {
            this.isOpen = !this.isOpen;

            this.$toggleEl.addClass('show');

            setTimeout(() => {
                this.$el.find('input[name="s"]').focus();
            }, 300);
        },
        onClose: function() {
            this.isOpen = !this.isOpen;

            this.$toggleEl.removeClass('show');

            this.$el.find('input[name="s"]').blur();
        },
        onKeydown: function(evt) {
            var key = evt.which || evt.keyCode || 0;

            if (key === 27 && this.isOpen) {
                this.$el.collapse('hide');
            }
        },
        onClickOutside: function(evt) {
            if (
                (!this.$el.is(evt.target) && this.$el.has(evt.target).length === 0) &&
                (!this.$toggleEl.is(evt.target) && this.$toggleEl.has(evt.target).length === 0) &&
                this.isOpen
            ) {
                this.$el.collapse('hide');
            }
        },
    };

    /**
     * Spa availability panel
     */
    var spaAvailabilityPanel = {
        $el: $('.js-spa-availability-panel'),
        $contentEl: $('.js-spa-availability-panel__content'),
        $toggleEl: $('.js-spa-availability-panel-btn'),
        isOpen: false,
        setup: function() {
            this.$el.on('show.bs.collapse', this.onOpen.bind(this));
            this.$el.on('hide.bs.collapse', this.onClose.bind(this));
            $(document).on('keydown', this.onKeydown.bind(this));
            $(document).on('click touchstart', this.onClickOutside.bind(this));
        },
        onOpen: function() {
            this.isOpen = !this.isOpen;

            this.$toggleEl.addClass('show');
        },
        onClose: function() {
            this.isOpen = !this.isOpen;

            this.$toggleEl.removeClass('show');
        },
        onKeydown: function(evt) {
            var key = evt.which || evt.keyCode || 0;

            if (key === 27 && this.isOpen) {
                this.$el.collapse('hide');
            }
        },
        onClickOutside: function(evt) {
            if (
                (!this.$contentEl.is(evt.target) && this.$contentEl.has(evt.target).length === 0) &&
                (!this.$toggleEl.is(evt.target) && this.$toggleEl.has(evt.target).length === 0) &&
                this.isOpen
            ) {
                this.$el.collapse('hide');
            }
        },
    };

    /**
     * Booking
     */
    var bookingSystem = {
        $dateEl: $('#check-in'),
        $endDate: '',

        setup: function() {
            this.initDatepicker();
            $(document.body).on('click', '.js-guest-btn--decrease', this.onDecreaseQuantity.bind(this));
            $(document.body).on('click', '.js-guest-btn--increase', this.onIncreaseQuantity.bind(this));
            $(document.body).on('blur', '.select-dropdown input[type="number"]', this.onQuantityInput.bind(this));
            $(document.body).on('click', '.js-select-dropdown', this.customDropdown.bind(this));
            $(document.body).on('click', '.select-dropdown #close-dropdown', this.closeDropdown.bind(this));
        },
        onQuantityInput: function(evt) {
            var $targetEl = $(evt.currentTarget);
            var $inputEl = $targetEl.closest('.quantity').find('input.qty');
            var $resultEl = $('.js-select-dropdown').find('input');
            var minQuantity = parseFloat($inputEl.attr('min')) || 0;
            var maxQuantity = parseFloat($inputEl.attr('max')) || 0;
            var quantity = parseFloat($inputEl.val()) || 0;

            if (quantity < minQuantity){
                $inputEl.val(minQuantity);
                $resultEl.val('');
            } else if (quantity > maxQuantity) {
                $inputEl.val(maxQuantity);
                $resultEl.val(maxQuantity + ' Adult');
            } else {
                this.updateQuantity(quantity, $inputEl);
                if (quantity > 1) {
                    $resultEl.val(quantity + ' Adult');
                } else {
                    $resultEl.val('');
                }
            }
        },
        onDecreaseQuantity: function(evt) {
            var $targetEl = $(evt.currentTarget);
            var $inputEl = $targetEl.closest('.quantity').find('input.qty');
            var $resultEl = $('.js-select-dropdown').find('input');
            var minQuantity = parseFloat($inputEl.attr('min')) || 0;
            var quantity = parseFloat($inputEl.val()) || 0;

            if (evt) {
                evt.preventDefault();
            }

            if (quantity > minQuantity) {
                var $updatedVal = quantity - 1;

                this.updateQuantity($updatedVal, $inputEl);
                if (quantity > 1) {
                    $resultEl.val($updatedVal + ' Adult');
                } else {
                    $resultEl.val('');
                }
            }
        },
        onIncreaseQuantity: function(evt) {
            var $targetEl = $(evt.currentTarget);
            var $inputEl = $targetEl.closest('.quantity').find('input.qty');
            var $resultEl = $('.js-select-dropdown').find('input');
            var maxQuantity = parseFloat($inputEl.attr('max')) || 0;
            var quantity = parseFloat($inputEl.val()) || 0;

            if (evt) {
                evt.preventDefault($inputEl.val());
            }

            if (!maxQuantity || quantity < maxQuantity) {
                var $updatedVal = quantity + 1;

                this.updateQuantity($updatedVal, $inputEl);
                $resultEl.val($updatedVal + ' Adult');
            }
        },
        updateQuantity: function(quantity, $inputEl) {
            $inputEl.val(quantity).change();
        },
        customDropdown: function (evt) {
            var $targetEl = $(evt.currentTarget);
            var $dropdownEl = $targetEl.next('.select-dropdown');

            if (evt) {
                evt.preventDefault();
            }

            if ($dropdownEl.hasClass('active')) {
                $dropdownEl.removeClass('active');
            } else {
                $dropdownEl.addClass('active');
            }
        },
        closeDropdown: function (evt) {
            var $dropdownEl = $('.select-box .select-dropdown');
            if ($dropdownEl.hasClass('active')) {
                $dropdownEl.removeClass('active');
            } else {
                $dropdownEl.addClass('active');
            }
        },
        initDatepicker: function() {
            $('#check-in').daterangepicker({
                "singleDatePicker": true,
                "alwaysShowCalendars": false,
                "linkedCalendars": false,
                "parentEl": '.js-booking-date',
                "minDate": new Date(),
                "maxSpan": {
                    "days": 90
                },
                "autoApply": true,
                "applyButtonClasses": 'btn btn-outline-primary btn-sm fw-semibold text-primary hover-text-white focus-text-white',
                "cancelButtonClasses": 'btn btn-outline-gray-100 text-black',
                "opens": "center",
                "drops": "down",
                locale: {
                    "applyLabel": "Done",
                    "cancelLabel": "Clear Dates",
                    "format": 'DD/MM/YYYY',
                    "monthNames": [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ],
                    "firstDay": 1
                }
            });

            $('#check-in').on('apply.daterangepicker', function (ev, picker) {
                $("#check-in").val(picker.startDate.format('DD/MM/YYYY'));

                $("#arr").val(picker.startDate.format('YYYYMMDD'));

                bookingSystem.$endDate = moment(picker.startDate.format('YYYYMMDD')).add(1, 'M').format('YYYYMMDD');
                console.log(bookingSystem.$endDate);


                if ($('#flexible-dates').prop("checked")) {
                    $("#arn").val(bookingSystem.$endDate);
                    $("#arn").attr('name', 'arn');
                }
            });

            $('#check-in').on('cancel.daterangepicker', function (ev, picker) {
                $("#check-in").val('');
                this.closeDropdown.bind($('.select-dropdown'));
            });

            $('#flexible-dates').on('change', function () {
                if ($(this).prop("checked")) {
                    $("#arn").val(bookingSystem.$endDate);
                    $("#arn").attr('name', 'arn');
                } else {
                    $("#arn").val('');
                    $("#arn").removeAttr('name');
                }
            });

            $('.drp-calendar.right').hide();
            $('.drp-calendar.left').addClass('single');

            $('.calendar-table').on('DOMSubtreeModified', function () {
                var el = $(".prev.available").parent().children().last();
                if (el.hasClass('next available')) {
                    return;
                }
                el.addClass('next available');
                el.append('<span></span>');
            });
        },
    };

    /**
     * Gift Wrap
     */
    var giftWrap = {
        $imagesEl: $('#gift_wrap_product_images'),
        $selectionInputEl: $('#gift_wrap_selection'),
        setup: function () {
            this.addMarkup();

            this.$imagesEl
                .find('.product_images .image')
                .off('click')
                .on('click', this.onClickImage.bind(this));
        },
        onClickImage: function (evt) {
            this.$imagesEl.find('.product_images .image .checked').removeClass('is-checked');
            this.$imagesEl.find('.product_images .image').removeClass('is-checked');

            $(evt.currentTarget).find('.checked').addClass('is-checked');
            $(evt.currentTarget).addClass('is-checked');

            this.$selectionInputEl.val($(evt.currentTarget).attr('data-attachment_id'));

            return false;
        },
        addMarkup: function () {
            this.$imagesEl
                .find('.product_images .image')
                .each(function () {
                    var img = $(this).find('.ign_zoom_link img');
                    var img_alt = img.attr('alt');
                    var actions = $(this).find('.actions');

                    actions.prepend('<li class="image_title">' + img_alt + '</li>');
                });
        },
    };

    /**
     * Add to Cart
     */
    var addToCart = {
        setup: function () {
            $(document.body).on('click', '.js-quantity-btn--decrease', this.onDecreaseQuantity.bind(this));
            $(document.body).on('click', '.js-quantity-btn--increase', this.onIncreaseQuantity.bind(this));
        },
        onDecreaseQuantity: function (evt) {
            var $targetEl = $(evt.currentTarget);
            var $inputEl = $targetEl.closest('.quantity').find('input.qty');
            var minQuantity = parseFloat($inputEl.attr('min')) || 1;
            var quantity = parseFloat($inputEl.val()) || 1;

            if (evt) {
                evt.preventDefault();
            }

            if (quantity > minQuantity) {
                this.updateQuantity(quantity - 1, $inputEl);
            }
        },
        onIncreaseQuantity: function (evt) {
            var $targetEl = $(evt.currentTarget);
            var $inputEl = $targetEl.closest('.quantity').find('input.qty');
            var maxQuantity = parseFloat($inputEl.attr('max')) || 0;
            var quantity = parseFloat($inputEl.val()) || 0;

            if (evt) {
                evt.preventDefault();
            }

            if (!maxQuantity || quantity < maxQuantity) {
                this.updateQuantity(quantity + 1, $inputEl);
            }
        },
        updateQuantity: function (quantity, $inputEl) {
            $inputEl.val(quantity).change();
        },
    };

    /**
     * Add to Cart Variation
     */
    var addToCartVariation = {
        setup: function () {
            $('.variations_form').on('change', '.variations input[type="radio"]', this.onAttributeChange.bind(this));
        },
        onAttributeChange: function (evt) {
            var $targetEl = $(evt.target);
            var value = $targetEl.val();
            var attributeName = $targetEl.data('attribute_name');

            $(`.variations select[name="${attributeName}"]`).val(value).trigger('change');
        },
    };

    /**
     * Products filters
     */
    var productsFilters = {
        setup: function () {
            $('.js-products-filters').on('change', 'select, input[type="checkbox"], input[type="radio"]', function () {
                $(this).closest('form').trigger('submit');
            });
        },
    };

    /**
     * Products load more
     */
    var productsLoadMore = {
        $el: $('.js-products-load-more'),
        total: 0,
        shown: 0,
        per_page: 0,
        setup: function () {
            this.$el.on('click', '.js-products-load-more__button', this.onLoadMore.bind(this));
        },
        onLoadMore: function (evt) {
            var $targetEl = $(evt.currentTarget);
            var $itemsEl = this.$el.find('.js-products-load-more__items');
            var $itemEl = $itemsEl.find('.js-products-load-more__item');

            this.total = $targetEl.data('total') || 0;
            this.per_page = $targetEl.data('per-page') || 0;

            var data = {
                action: 'product_load_more',
                nonce: site_params.product_load_more_nonce,
                term: $targetEl.data('term') || '',
                category: $targetEl.data('category') || '',
                price: $targetEl.data('price') || '',
                brand: $targetEl.data('brand') || '',
                per_page: this.per_page,
                orderby: $targetEl.data('orderby') || '',
                offset: $itemEl.length,
                item_classes: $targetEl.data('item-classes') || '',
            }

            $targetEl.prop('disabled', true)
                .addClass('is-loading');

            $.ajax({
                url: site_params.ajax_url,
                data: data,
                dataType: 'html',
                type: 'GET',
                success: function (response) {
                    productsLoadMore.shown = $itemEl.length;

                    $targetEl.prop('disabled', false)
                        .removeClass('is-loading');

                    if ((productsLoadMore.shown + productsLoadMore.per_page) >= productsLoadMore.total) {
                        $targetEl.addClass('d-none');
                    }

                    if (!(response === '-1' || response.replace(/(\r\n|\n|\r)/gm, '') === '-1')) {
                        const $response = $(response);
                        $itemsEl.append($response);

                        productsLoadMore.animateItems();
                    }
                }
            });

            return false;
        },
        animateItems: function () {
            var $itemsEl = this.$el.find('.js-products-load-more__item.animate');
            var counter = 0;

            new imagesLoaded(this.$el, function () {
                $itemsEl.each(function (i, el) {
                    var $el = $(el);
                    var $windowEl = $(window);

                    if (!$el.hasClass('animated')) {
                        counter += 250;

                        setTimeout(() => {
                            var perspY = $windowEl.scrollTop() + $windowEl.height() / 2;

                            $el.css('perspective-origin', '50% ' + perspY + 'px');
                            $el.addClass('animated');
                        }, counter);
                    }
                });
            });
        },
    };

    $(document).ready(function () {
        replaceSVGs();
        dropdown.setup();
        select2.setup();
        headerSearch.setup();
        spaAvailabilityPanel.setup();
        bookingSystem.setup();
        giftWrap.setup();
        addToCart.setup();
        addToCartVariation.setup();
        productsFilters.setup();
        productsLoadMore.setup();

        $(document).on("sf:ajaxfinish", ".searchandfilter", function () {
            var target = $("#sf-results");
            scrollToPlusHeader(target);
        });

        //Responsive Header JS
        $('.js-header__menu-toggle').on('click', function (e) {
            var $el = $(this);
            var $headerMenuEl = $('#header .js-header__menu');

            if ($el.hasClass('active')) {
                $('.js-header__menu-toggle').removeClass('active');
                $el.removeClass('active');
                $headerMenuEl.removeClass('active');
            } else {
                $('.js-header__menu-toggle').addClass('active');
                $el.addClass('active');
                $headerMenuEl.addClass('active');
            }
        });

        $('#header nav li a .expand').on('click', function (e) {
            e.preventDefault();
            var _this = $(this).parent('a');
            var _target = $(e.target);
            var _parent = _this.parent('li');
            if (_target.parent('.expand').is(':visible')) {
                var _submenu = _parent.children('.sub-menu').first();
                $('> a .expand', _parent).toggleClass('close');
                _parent.children('a span .expand').first().toggleClass('close');
                if (_submenu.hasClass('active')) {
                    _submenu.removeClass('active').slideUp();
                } else {
                    _submenu.addClass('active').slideDown();
                }
            }
        });

        //Header Search
        /*$('#header .search-link').on('click', function(e){
            e.preventDefault();
            var searchLink = $(this),
                searchContainer = $('#header .search-container');

            searchLink.find('.icon').toggleClass('fa-search fa-times');

            if (searchLink.hasClass('active')) {
                searchLink.removeClass('active');
            } else {
                searchLink.addClass('active');
            }

            if (searchContainer.is(':visible')) {
                searchContainer.find('input').focus();
            }
        });*/

        var hash = window.location.hash,
            target = hash.replace('#', '');

        $(window).load(function () {
            if (target) {
                target = $("#" + target);
                var tabattr = target.parent().data('bs-target');
                if (typeof tabattr !== typeof undefined && tabattr !== false) {
                    target.parent().removeClass('collapsed');
                    $(tabattr).addClass('show');
                }
                scrollToPlusHeader(target);
            }
        });

        //Image Gallery JS
        $(document).on('click', '#nextLink a', function (e) {
            e.preventDefault();
            $('#loadContent').addClass('loading');
            $('#processContent').load($(this).attr('href') + ' #loadContent', function () {
                var galleryGrid = $('#gridContent');
                var _process = $(this);
                var _items = $('#gridContent .image-item', _process);
                galleryGrid.isotope('insert', _items);
                $('#loadContent #nextLink').html($('#nextLink', _process).html());
                _process.html('');
                $('#loadContent').removeClass('loading');
                $('img', _items).on('load', function () {
                    galleryGrid.isotope('layout');
                });

            });
            return false;
        });

        //Tabs JS
        /*$('.tab-area .tab-link, .tab-container .tab-link').on('click', function(e) {
            e.preventDefault();
            var _this	= $(this);
            var _parent = _this.parent();
            var _target = $(_this.attr('href'));
            var _active = $('.tab-button.active,.tab-content .content.active, .tab.active, .tab-content .tab-content-container.active');
            var _tabHeading;

            if (_parent.hasClass('accordion-heading')) {
                _tabHeading = _parent.closest('.tab-content-container');
                $('.tab-area .tabs .tab').each(function() {
                    var _link =  $(this).find('.tab-link');
                    var _linkTarget = _link.attr('href');
                    _linkTarget = _linkTarget.replace("#","");

                    if (_linkTarget == _target.attr('id')) {
                        $(this).addClass('active');
                    }
                });
            } else {
                _tabHeading = _parent;
            }

            _active.removeClass('active');
            _tabHeading.addClass('active');
            _target.addClass('active');

            scrollToPlusHeader(_target);
        });

        $('.tab-area').each(function(){
            var _tabsSection = $(this);
            _tabsSection.find('.tabs .tab').first().addClass('active');
            _tabsSection.find('.tab-content .tab-content-container').first().addClass('active');
        });*/

        $("a.tab-jump, a.woocommerce-review-link").click(function (event) {
            event.preventDefault();

            var href = $(this).attr("href");
            var href_id = href.substring(href.indexOf('#'));

            scrollToPlusHeader(href_id);
        });

        //FAQ Accordion
        /*$('.faq').on('click', function() {
            $(this).toggleClass('active');
        });

        $('.faq-category-title').on('click', function() {
            $(this).parent().toggleClass('active');
        });*/

        //Embedded Video Click
        $(".video-button").click(function () {
            $(".video-thumbnail img, .video-thumbnail .overlay").css({ "opacity": "0", "display": "block" });
            $("iframe", '.video-button').css({ "opacity": "1", "display": "block" });
            $("iframe", '.video-button')[0].src += "&autoplay=1";
            $(this).unbind("click");
            $(this).addClass("active");
        });

        $('.contra-indications .toggle-title').on('click', function () {
            $(this).parent().toggleClass('active');
        });

        $('.expandable-content .title').on('click', function (e) {
            e.preventDefault();
            var _parentDiv = $(this).parent('.expandable-content');
            if (_parentDiv.hasClass('active')) {
                _parentDiv.find('.content').slideUp();
                _parentDiv.removeClass('active');
            } else {
                _parentDiv.find('.content').slideDown();
                _parentDiv.addClass('active');
            }
        });

        /*$('.searchandfilter ul > li').each(function() {
            var filterArea = $(this);
            var listHeading = filterArea.find('h4');
            var filterList = listHeading.next('ul');
            var selector = filterList.find("input[type='radio'], input[type='checkbox']");
            listHeading.append('<span class="expand"><i class="icon fa fa-chevron-down"></i></span>');

            if (filterArea.hasClass('sf-field-taxonomy-treatment_category')) {
                if (filterList.find('.sf-option-active').length == 0) {
                    filterList.css('display', 'block');
                }
            }

            if (filterArea.hasClass('sf-field-taxonomy-product_cat')) {
                if (filterList.find('.sf-option-active').length == 0) {
                    filterList.css('display', 'block');
                }
            }

            if (filterArea.hasClass('sf-field-post-meta-treatments_included')) {
                if (filterList.find('.sf-option-active').length == 0) {
                    filterList.css('display', 'block');
                }
            }

            if (filterList.find('.sf-option-active').length > 0) {
                filterList.css('display', 'block');
            }

        });*/

        /*$('.searchandfilter ul > li > h4 .expand').on('click', function() {
            var expand = $(this);
            var list = expand.closest('li');
            var filterList = list.children('ul');
            if (expand.hasClass('active')) {
                expand.removeClass('active');
                filterList.slideUp();
            } else {
                expand.addClass('active');
                filterList.slideDown();
            }
        });*/

        //Popup JS
        var modal = document.getElementById('popup_background');
        var span = document.getElementsByClassName("js-popup-close")[0];

        if (span) {
            span.onclick = function () {
                modal.style.display = "none";
            }
        }

        // window.onclick = function(event) {
        // 	if (event.target == modal) {
        // 		modal.style.display = "none";
        // 	}
        // }

        $('.upload-image').on('click', function (e) {
            e.preventDefault();
            modal.style.display = "block";
        });

        Cookie.get('user_first_visited') || Cookie.set('user_first_visited', Date.now());

        //check popup triggerred or not and the popup is on the page
        //otherwise the cookie can get set and the popup is never shown
        if (!Cookie.get('user_popup_triggerred') && $('#popup_background').length > 0) {

            var loopDetect = setInterval(function(){
                var timeElapsed = (Date.now() - Cookie.get('user_first_visited')) / 1000;
                if (timeElapsed > 30) {
                    var date = new Date();
                    var minutes = 30;

                    date.setTime(date.getTime() + (minutes * 60 * 1000));

                    $('#popup_background').show();
                    clearInterval(loopDetect);
                    Cookie.set('user_popup_triggerred', 1, {
                        expires: date,
                        path: '/'
                    });
                }
            }, 1000);
        }

        window.addEventListener('touchstart', function onFirstTouch() {
            document.body.classList.add('touch-screen');
            window.removeEventListener('touchstart', onFirstTouch, false);
        }, false);

        $(".wc-pao-addon-would-you-like-your-gift-to-be input[type='checkbox']").on('change', function () {
            var input = $(this);
            var add_on = input.closest('.wc-pao-addon');
            var count = add_on.find("input[type='checkbox']:checked").length;
            var other_add_ons = add_on.nextAll(".wc-pao-addon");
            var other_add_ons_required = add_on.nextAll(".wc-pao-addon").not('.wc-pao-addon-gift-wrap-options');
            var gift_wrap_html = $('.gift-wrap-container');

            if (count > 0) {
                if (count > 1) {
                    var form_row = input.closest('.form-row');
                    form_row.siblings().find('input[type="checkbox"]:checked').prop("checked", false);
                }

                if (input.val() == "an-e-voucher"
                    || input.val() == "emailed-to-you"
                    || input.val() == "sent-as-an-e-voucher"
                    || input.val() == "sent-in-a-gift-voucher-wallet"
                    || input.val() == "sent-in-a-gift-voucher-wallet-free"
                    || input.val() == "sent-as-a-gift-voucher-wallet") {
                    other_add_ons.removeClass("active");
                    gift_wrap_html.removeClass("active");

                    //Remove required state of gift wrap
                    $('#gift_wrap').prop("checked", false);
                } else {
                    other_add_ons.addClass("active");
                    gift_wrap_html.addClass("active");

                    //To set gift wrap as required
                    $('#gift_wrap').prop("checked", true);
                }
            } else {
                other_add_ons.removeClass("active");
                gift_wrap_html.removeClass("active");

                //Remove required state of gift wrap
                $('#gift_wrap').prop("checked", false);

                //Remove required state of add-on fields
                other_add_ons_required.find('input, textarea').prop('required', false);
            }
        });

        $(document).on('click', '.postcode-search .frm_inline_box', function () {
            var button = $(this);
            var postcode_field = button.closest('.postcode-search');
            var lookup_fields = postcode_field.nextAll('.address-lookup');
            var fields = lookup_fields.find('.frm_form_field');

            fields.each(function (index) {
                var input_id = $(this).find('input, select').attr('id');
                if (input_id.indexOf("zip") >= 0) {
                    var postcode = postcode_field.find('input').val();
                    $('#' + input_id).val(postcode);
                }
                if (input_id.indexOf("country") >= 0) {
                    $('#' + input_id).val('United Kingdom');
                    $('#' + input_id).parent('.frm_form_field').hide();
                }
            });

            if (postcode_field.parent().hasClass('frm_repeat_sec')) {
                var key = postcode_field.siblings('.new-guest').find('.frm_remove_form_row').data('key');
                cp_obj_array[key].doLookup();
                lookup_fields.show();
            } else {
                cp_obj.doLookup();
                lookup_fields.show();
            }
        });

        if ($('#form_guestinformationrequestform').length) {

            var cp_obj = CraftyPostcodeCreate();
            cp_obj.set("access_token", "bb489-58eb1-d598a-05b50");
            cp_obj.set("result_elem_id", 'cc-results');
            cp_obj.set("form", ""); // left blank, we will use element id's
            cp_obj.set("elem_street1", "field_s2241_line1");
            cp_obj.set("elem_street2", "field_s2241_line2");
            cp_obj.set("elem_town", "field_s2241_city");
            cp_obj.set("elem_postcode", "field_mdd63"); //must be the id of the postcode lookup field

            if ($(this).find('.frm_error_style').length) {
                var addresses = $(this).find('.address-lookup');
                addresses.each(function (index) {
                    var key = $(this).next('.new-guest').find('.frm_remove_form_row').data('key');
                    var postcode_field = $(this).prevAll('.postcode-search');
                    var fields = $(this).find('.frm_form_field');

                    var input_id = $(this).find('input, select').attr('id');
                    if (input_id.indexOf("country") >= 0) {
                        $('#' + input_id).parent('.frm_form_field').hide();
                    }

                    $(this).show();
                    initialiseCraftyClicks(key, postcode_field, fields);
                });
            }
        }

        $('.new-guest input').on('click', function () {
            if ($(this).val() == "Yes") {
                var guests = $(this).closest('.new-guest').parent('.frm_section_heading').next('.additional-guests').find('.frm_repeat_sec');
                guests.each(function (index) {
                    var key = $(this).find('.new-guest .frm_remove_form_row').data('key');
                    var postcode_field = $(this).find('.postcode-search');
                    var fields = $(this).find('.address-lookup').find('.frm_form_field');
                    initialiseCraftyClicks(key, postcode_field, fields);
                });
            }
        });

        $(document).on('click', '.new-guest .frm_add_form_row', function () {
            var button = $(this);

            $(document).ajaxComplete(function () {
                var section = button.closest('.frm_repeat_sec').next('.frm_repeat_sec');
                var postcode_field = section.find('.postcode-search');
                var fields = section.find('.address-lookup').find('.frm_form_field');
                var key = (section.find('.new-guest .frm_remove_form_row').data('key'));
                initialiseCraftyClicks(key, postcode_field, fields);
            });
        });
    });

    $(".single_variation_wrap").on("show_variation", function (event, variation) {
        $('.woocommerce-product-gallery__wrapper img')
            .attr({ 'src': variation.image.full_src, 'srcset': variation.image.srcset, 'class': 'h-100', 'width': variation.image.full_src_w, 'height': variation.image.full_src_h })
            .css({ 'max-width': variation.image.full_src_w, 'max-height': variation.image.full_src_h });
    });

    intersectElements([...document.querySelectorAll('[data-intersect-section]')], {
        observerProps: {
            threshold: [0.4]
        },
        on: (item, observer) => {
            item.target.classList.add('section-active');
            observer.unobserve(item.target);
        },
    });

    // Animate the numbers in the statistics banner
    let statisticsBanner = document.querySelector('.statistic-number');

    if (statisticsBanner) {
        let inited = false;

        let callback = (entries, observer) => {
            if (inited) {
                return;
            }

            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    inited = true;

                    $('.statistic-number').each(function () {
                        $(this).prop('Counter',0).animate({
                            Counter: parseInt($(this).text().split(',').join(''))
                        }, {
                            duration: 4000,
                            easing: 'swing',
                            step: function (now) {
                                $(this).text(Math.ceil(now));
                            }
                        });
                    });
                }
            });
        }

        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.9
        }

        let observer = new IntersectionObserver(callback, options);

        observer.observe(document.querySelector('.statistics-banner-section'));
    }
})(jQuery);
